const Reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case "homeValue":
      return { ...state, homeValue: action.payload };
    case "downPayment":
      return { ...state, downPayment: action.payload };
    case "loanAmount":
      return { ...state, loanAmount: action.payload };
    case "interestRate":
      return { ...state, interestRate: action.payload };
    case "loanTerm":
      return { ...state, loanTerm: action.payload };
    case "startDateMonth":
      return { ...state, startDateMonth: action.payload };
    case "startDateYear":
      return { ...state, startDateYear: action.payload };
    case "propertyTax":
      return { ...state, propertyTax: action.payload };
    case "pmi":
      return { ...state, pmi: action.payload };
    case "homeIns":
      return { ...state, homeIns: action.payload };
    case "monthlyHoa":
      return { ...state, monthlyHoa: action.payload };
    case "loanType":
      return { ...state, loanType: action.payload };
    case "options":
      return { ...state, options: action.payload };
    default:
      return state;
  }
};
export default Reducer;

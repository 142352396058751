import React, { useState } from "react";
import Styled from "styled-components";
import { useForm } from "react-hook-form";
import Axios from "axios";

import { SubscribeForm, Fieldset, Input, Span, Label, EMAIL_REGEX, Success, Error } from "@components/Form";
import Button from "@components/Button";

import { SERVERLESS_URL } from "@config";

const Container = Styled.div`
    width: 60%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px) {
        width: 90%;
    }
    h6 {
        font-family: ${({ theme }) => theme.fontSecondary};
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0px;
        margin-bottom: 1rem;
    }
`;

const Subscribe = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { register, handleSubmit, errors, setValue } = useForm();
  const onSubmit = async ({ email }) => {
    try {
      setLoading(true);
      setSuccess("");
      setError("");
      const { data } = await Axios.post(`${SERVERLESS_URL}/subscribe`, JSON.stringify({ email }));
      setSuccess(data);
      setValue("email", "");
      setLoading(false);
    } catch (error) {
      console.log("Subscribe Error: ", error);
      setError(error);
      setLoading(false);
    }
  };
  // ----------------------------------------------------------------
  return (
    <Container>
      <h6>Sign up to hear about my new listings.</h6>
      <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
        <Fieldset>
          <Input
            name="email"
            placeholder=" "
            type="email"
            ref={register({
              pattern: {
                value: EMAIL_REGEX,
                message: "Email is invalid",
              },
              required: "Please enter email",
            })}
          />
          <Label htmlFor="email">{errors.email ? <Span>{errors.email.message}</Span> : "Email Address"}</Label>
        </Fieldset>
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {loading ? "Registering..." : "Sign Up"}
        </Button>
      </SubscribeForm>
      {error && <Error>{error}</Error>}
      {success && <Success>{success}</Success>}
    </Container>
  );
};

export default Subscribe;

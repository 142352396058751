import React, { useState, useRef, useEffect } from "react";
import Styled from "styled-components";
import { Container } from "./styles";
import { useOnClickOutside } from "@hooks";

export const Label = Styled.label`
    user-select: none;
    font-family: ${({ theme }) => theme.fontSecondary};
    color: ${({ theme }) => theme.black};
    font-size: 1rem;
    font-weight: 700;
    position: relative;
    bottom: 10px;
`;

const Select = ({
  options,
  selectProps = null,
  value,
  error = false,
  name,
  placeholder = "Select",
  gridArea = null,
  onSelect = null,
  disabled = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const selectRef = useRef(null);
  useOnClickOutside(selectRef, () => {
    setOpen(false);
  });

  //   useEffect(() => {
  //     const element = document.getElementById(name);
  //     if (element) {
  //       element.value = selected;
  //     }
  //   }, [selected, name]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Container isOpen={isOpen} error={error} gridArea={gridArea}>
      <Label htmlFor={name}>{placeholder}</Label>
      <select {...selectProps} disabled={disabled} id={name} name={name} className="html-select">
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <div
        aria-hidden="true"
        ref={selectRef}
        onClick={() => {
          if (!disabled) {
            setOpen((prev) => !prev);
          }
        }}
        className="custom-select-wrapper"
      >
        <div className={`custom-select ${isOpen && "open"}`}>
          <div className="custom-select__trigger">
            <span>{selected.label ? selected.label : error ? error : placeholder}</span>
            <div className="arrow"></div>
          </div>
          <div className="custom-options">
            {options.map((item) => (
              <div
                aria-hidden="true"
                key={item.value}
                onClick={() => {
                  if (onSelect) {
                    onSelect(item);
                  } else {
                    setSelected(item);
                  }
                }}
                className="option-container"
              >
                <span className={`custom-option ${selected.value === item.value && "selected"} `} data-value={item.value}>
                  {item.label}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Select;

import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { Section } from "@components/StyledComponents";
import ListingsGallery from "@components/ListingsGallery";
import MortageCalculator from "@components/MortageCalculator";
// import Search from "@components/Search";
import Subscribe from "@components/Subscribe";

const IndexPage = ({ data }) => {
  const header = data.header.childImageSharp.fluid;
  const pdfs = data.pdfs.edges;
  const images = data.images.edges;
  return (
    <Layout
      seoTitle="Home"
      seoDescription="Welcome to Cassandra Lee & Co"
      fluid={header}
      alt="Welcome to Cassandra Lee & Co"
      headerTitle="Buy.Sell.Invest"
      headerSubTitle="Your home is my commitment"
    >
      <Section>
        <h2>Active Listings</h2>
        <hr />
        <ListingsGallery pdfs={pdfs} images={images} />
      </Section>
      <Section dark>
        <h2>Mortage Calculator</h2>
        <hr />
        <MortageCalculator />
      </Section>
      {/* <Section dark>
        <h2>Find Your Dream Home</h2>
        <hr />
        <Search />
      </Section> */}
      <Section>
        <h2>Subscribe</h2>
        <hr />
        <Subscribe />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery {
    header: file(relativePath: { eq: "unsplash_1_1200x798.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pdfs: allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "listings" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
  }
`;

export default IndexPage;

export const numberWithCommas = (x) => {
  let num_parts = x.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};
export const numberWithDollars = (x) => {
  return `$${x}`;
};
export const formatOutput = (x) => {
  return numberWithDollars(numberWithCommas(x.toFixed(2)));
};
export const formatPercent = (x) => {
  let p = x * 100;
  return `${p.toFixed(2)}%`;
};
// ------------------------------------------------------------------------------------------------
export const calculatePMIPerMonth = (loanAmount, pmi) => {
  const pmiPerYear = (loanAmount * pmi) / 100;
  const pmiPerMonth = pmiPerYear / 12;
  return formatOutput(pmiPerMonth);
};
export const calculatePMIPerYear = (loanAmount, pmi) => {
  const pmiPerYear = (loanAmount * pmi) / 100;
  return formatOutput(pmiPerYear);
};
export const calculatePayOffDate = (m, y, term) => {
  const m_delta = MONTHS_REVERSE[m] - 1 >= 0 ? MONTHS_REVERSE[m] - 1 : 11;
  const c = new Date(y + term, m_delta, 1);
  const month = c.getMonth();
  const year = c.getFullYear();
  return `${MONTHS_VERSE[month]}, ${year}`;
};
export const calculateLoanInformation = (loanAmount, interestRate, loanTerm, pmi, homeIns, propertyTax) => {
  const principal = loanAmount;
  const monthlyInterest = interestRate / 100 / 12;
  const pmiPerMonth = (loanAmount * pmi) / 100 / 12;
  const homeInsPerMonth = homeIns / 12;
  const taxPerMonth = propertyTax / 12;
  const monthlyPayment = (monthlyInterest * principal) / (1 - Math.pow(1 + monthlyInterest, loanTerm * -12));
  const totalWithPMI = monthlyPayment + pmiPerMonth + homeInsPerMonth + taxPerMonth;
  const totalWithoutPMI = monthlyPayment + homeInsPerMonth + taxPerMonth;
  return { totalWithPMI: formatOutput(totalWithPMI), totalWithoutPMI: formatOutput(totalWithoutPMI) };
};
export const calculateTotalInterest = (loanAmount, interestRate, loanTerm) => {
  const principal = loanAmount;
  const monthlyInterest = interestRate / 100 / 12;
  const numberOfPayments = loanTerm * 12;
  const monthlyPayment = (monthlyInterest * principal) / (1 - Math.pow(1 + monthlyInterest, loanTerm * -12));
  const totalInterest = monthlyPayment * numberOfPayments - principal;
  return formatOutput(totalInterest);
};
export const calculateAnnualLoan = (loanAmount, interestRate, loanTerm) => {
  const principal = loanAmount;
  const monthlyInterest = interestRate / 100 / 12;
  const numberOfPayments = loanTerm * 12;
  const monthlyPayment = (monthlyInterest * principal) / (1 - Math.pow(1 + monthlyInterest, loanTerm * -12));
  const totalInterest = monthlyPayment * numberOfPayments - principal;
  const totalLoan = ((principal + totalInterest) / numberOfPayments) * 12;
  return formatOutput(totalLoan);
};
export const calculateTotalLoan = (loanAmount, interestRate, loanTerm) => {
  const principal = loanAmount;
  const monthlyInterest = interestRate / 100 / 12;
  const numberOfPayments = loanTerm * 12;
  const monthlyPayment = (monthlyInterest * principal) / (1 - Math.pow(1 + monthlyInterest, loanTerm * -12));
  const totalInterest = monthlyPayment * numberOfPayments - principal;
  const totalLoan = principal + totalInterest;
  return formatOutput(totalLoan);
};
// ------------------------------------------------------------------------------------------------
const generateArrayOfAmounts = () => {
  const min = 10000;
  const max = 10000000;
  const amounts = [];
  for (let i = min; i < max; i += 10000) {
    amounts.push({ value: i, label: `$${numberWithCommas(i)}` });
  }
  return amounts;
};
const generateArrayOfRates = () => {
  const min = 0.1;
  const max = 25.0;
  const rates = [];
  for (let i = min; i < max; i += 0.1) {
    rates.push({ value: i.toFixed(1), label: `${i.toFixed(1)}%` });
  }
  return rates;
};
const generateArrayOfTerms = () => {
  const min = 5;
  const max = 50;
  const terms = [];
  for (let i = min; i < max; i += 1) {
    terms.push({ value: i, label: `${i} years` });
  }
  return terms;
};
const generateArrayOfYears = () => {
  const min = new Date().getFullYear();
  const max = min + 10;
  const years = [];
  for (let i = min; i < max; i++) {
    years.push({ value: i, label: `${i}` });
  }
  return years;
};
const generateArrayOfMoneys = () => {
  const min = 0;
  const max = 10000;
  const taxes = [];
  for (let i = min; i < max; i += 100) {
    taxes.push({ value: i, label: `$${numberWithCommas(i)}` });
  }
  return taxes;
};
const generateArrayOfPmis = () => {
  const min = 0;
  const max = 2.5;
  const pmis = [];
  for (let i = min; i < max; i += 0.01) {
    pmis.push({ value: i.toFixed(2), label: `${i.toFixed(2)}%` });
  }
  return pmis;
};
// ------------------------------------------------------------------------------------------------
export const AMOUNTS = generateArrayOfAmounts();
export const RATES = generateArrayOfRates();
export const TERMS = generateArrayOfTerms();
export const MONTHS = [
  { value: "Jan", label: "Jan" },
  { value: "Feb", label: "Feb" },
  { value: "Mar", label: "Mar" },
  { value: "Apr", label: "Apr" },
  { value: "May", label: "May" },
  { value: "Jun", label: "Jun" },
  { value: "Jul", label: "Jul" },
  { value: "Aug", label: "Aug" },
  { value: "Sep", label: "Sep" },
  { value: "Oct", label: "Oct" },
  { value: "Nov", label: "Nov" },
  { value: "Dec", label: "Dec" },
];
const MONTHS_VERSE = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const MONTHS_REVERSE = { Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5, Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11 };
export const YEARS = generateArrayOfYears();
export const TAXES = generateArrayOfMoneys();
export const PMIS = generateArrayOfPmis();
export const INSURANCES = generateArrayOfMoneys();
export const HOAS = generateArrayOfMoneys();
// ------------------------------------------------------------------------------------------------
export const getLoanAmountIdx = (homeValue, downPayment) => {
  const cost = homeValue.value - downPayment.value;
  const idx = AMOUNTS.findIndex((x) => x.value === cost);
  return idx;
};

import React, { useReducer } from "react";
import Styled from "styled-components";

import { MortageForm, MortageOutput, Output } from "@components/Form";
import Select from "@components/Select";

import InitialState from "./InitialState";
import Reducer from "./Reducer";
import {
  AMOUNTS,
  RATES,
  TERMS,
  MONTHS,
  YEARS,
  TAXES,
  PMIS,
  INSURANCES,
  HOAS,
  formatOutput,
  formatPercent,
  calculatePMIPerMonth,
  calculatePMIPerYear,
  calculatePayOffDate,
  calculateTotalInterest,
  calculateLoanInformation,
  calculateAnnualLoan,
  calculateTotalLoan,
  getLoanAmountIdx,
} from "./_helpers";

const Container = Styled.div`
    width: 80%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px) {
        width: 90%;
    }
`;

const MortageCalculator = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  // ----------------------------------------------------------------
  return (
    <Container>
      <MortageForm onSubmit={(e) => e.preventDefault()}>
        <Select
          gridArea="homeValue"
          options={AMOUNTS}
          value={state.homeValue}
          placeholder="Home Value"
          name="homeValue"
          onSelect={(val) => {
            dispatch({ type: "homeValue", payload: val });
            dispatch({ type: "loanAmount", payload: AMOUNTS[getLoanAmountIdx(val, state.downPayment)] });
          }}
        />
        <Select
          gridArea="downPayment"
          options={AMOUNTS}
          value={state.downPayment}
          placeholder="Down Payment"
          name="downPayment"
          onSelect={(val) => {
            dispatch({ type: "downPayment", payload: val });
            dispatch({ type: "loanAmount", payload: AMOUNTS[getLoanAmountIdx(state.homeValue, val)] });
          }}
        />
        <Select
          gridArea="loanAmount"
          options={AMOUNTS}
          value={state.loanAmount}
          disabled
          placeholder="Loan Amount"
          name="loanAmount"
          onSelect={(val) => dispatch({ type: "loanAmount", payload: val })}
        />
        <Select
          gridArea="interestRate"
          options={RATES}
          value={state.interestRate}
          placeholder="Interest Rate"
          name="interestRate"
          onSelect={(val) => dispatch({ type: "interestRate", payload: val })}
        />
        <Select
          gridArea="loanTerm"
          options={TERMS}
          value={state.loanTerm}
          placeholder="Loan Term"
          name="loanTerm"
          onSelect={(val) => dispatch({ type: "loanTerm", payload: val })}
        />
        <Select
          gridArea="startDateMonth"
          options={MONTHS}
          value={state.startDateMonth}
          placeholder="Start Date Month"
          name="startDateMonth"
          onSelect={(val) => dispatch({ type: "startDateMonth", payload: val })}
        />
        <Select
          gridArea="startDateYear"
          options={YEARS}
          value={state.startDateYear}
          placeholder="Start Date Year"
          name="startDateYear"
          onSelect={(val) => dispatch({ type: "startDateYear", payload: val })}
        />
        <Select
          gridArea="propertyTax"
          options={TAXES}
          value={state.propertyTax}
          placeholder="Property Tax"
          name="propertyTax"
          onSelect={(val) => dispatch({ type: "propertyTax", payload: val })}
        />
        <Select
          gridArea="pmi"
          options={PMIS}
          value={state.pmi}
          placeholder="PMI"
          name="pmi"
          onSelect={(val) => dispatch({ type: "pmi", payload: val })}
        />
        <Select
          gridArea="homeIns"
          options={INSURANCES}
          value={state.homeIns}
          placeholder="Home Insurance"
          name="homeIns"
          onSelect={(val) => dispatch({ type: "homeIns", payload: val })}
        />
        <Select
          gridArea="monthlyHoa"
          options={HOAS}
          value={state.monthlyHoa}
          placeholder="Monthly HOA"
          name="monthlyHoa"
          onSelect={(val) => dispatch({ type: "monthlyHoa", payload: val })}
        />
        {/* ---------------------------------------------------------------- */}
        <MortageOutput>
          <h3>Mortage Repayment Summary</h3>
          <Output>
            {
              calculateLoanInformation(
                state.loanAmount.value,
                state.interestRate.value,
                state.loanTerm.value,
                state.pmi.value,
                state.homeIns.value,
                state.propertyTax.value
              ).totalWithPMI
            }
            <span>Payment With PMI</span>
          </Output>
          <Output>
            {
              calculateLoanInformation(
                state.loanAmount.value,
                state.interestRate.value,
                state.loanTerm.value,
                state.pmi.value,
                state.homeIns.value,
                state.propertyTax.value
              ).totalWithoutPMI
            }
            <span>Payment Without PMI</span>
          </Output>
          {/* ---------------------------------------------------------------- */}
          <Output>
            {calculatePMIPerMonth(state.loanAmount.value, state.pmi.value)}
            <span>PMI Payment Per Month</span>
          </Output>
          <Output>
            {calculatePMIPerYear(state.loanAmount.value, state.pmi.value)}
            <span>PMI Payments Per Year</span>
          </Output>
          {/* ---------------------------------------------------------------- */}
          <Output>
            {formatOutput(state.downPayment.value)}
            <span>Down payment amount</span>
          </Output>
          <Output>
            {formatPercent(state.downPayment.value / state.homeValue.value)}
            <span>Down payment %</span>
          </Output>
          {/* ---------------------------------------------------------------- */}
          <Output>
            {calculatePayOffDate(state.startDateMonth.value, state.startDateYear.value, state.loanTerm.value)}
            <span>Loan Pay-Off Date</span>
          </Output>
          <Output>
            {calculateTotalInterest(state.loanAmount.value, state.interestRate.value, state.loanTerm.value)}
            <span>Total Interest Paid</span>
          </Output>
          {/* ---------------------------------------------------------------- */}
          <Output>
            {formatOutput(state.propertyTax.value / 12)}
            <span>Monthly Tax Paid</span>
          </Output>
          <Output>
            {formatOutput(state.propertyTax.value * state.loanTerm.value)}
            <span>Total Tax Paid</span>
          </Output>
          {/* ---------------------------------------------------------------- */}
          <Output>
            {formatOutput(state.homeIns.value / 12)}
            <span>Monthly Home Insurance</span>
          </Output>
          <Output>
            {formatOutput(state.homeIns.value * state.loanTerm.value)}
            <span>Total Home Insurance</span>
          </Output>
          {/* ---------------------------------------------------------------- */}
          <Output>
            {calculateAnnualLoan(state.loanAmount.value, state.interestRate.value, state.loanTerm.value)}
            <span>Annual Payment Amount</span>
          </Output>
          <Output>
            {calculateTotalLoan(state.loanAmount.value, state.interestRate.value, state.loanTerm.value)}
            <span>Total of {state.loanTerm.value * 12} Payments</span>
          </Output>
        </MortageOutput>
      </MortageForm>
    </Container>
  );
};

export default MortageCalculator;

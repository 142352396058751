import {
  AMOUNTS,
  TERMS,
  MONTHS,
  YEARS,
  PMIS,
  RATES,
  TAXES,
  INSURANCES,
  HOAS,
  getLoanAmountIdx,
} from "./_helpers"

const InitialState = {
  homeValue: AMOUNTS[29],
  downPayment: AMOUNTS[5],
  loanAmount: AMOUNTS[getLoanAmountIdx(AMOUNTS[29], AMOUNTS[5])],
  interestRate: RATES[24],
  loanTerm: TERMS[25],
  startDateMonth: MONTHS[new Date().getMonth()],
  startDateYear: YEARS[0],
  propertyTax: TAXES[24],
  pmi: PMIS[0],
  homeIns: INSURANCES[10],
  monthlyHoa: HOAS[0],
}

export default InitialState
